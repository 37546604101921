/* eslint-disable @typescript-eslint/no-var-requires */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";

import PerfectScrollbar, { PerfectScrollbar as ps } from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

import { createPinia, PiniaVuePlugin } from "pinia";
import Component from 'vue-class-component'
import EditArea from "./components/EditArea.vue"
import SearchToolBar from "./components/SearchToolBar.vue"
import EditToolBar from "./components/EditToolBar.vue"
import InnerToolBar from "./components/InnerToolBar.vue"
import EditInnerToolBar from "./components/EditInnerToolBar.vue"
import LoaderAnimation from "./components/LoaderAnimation.vue"
import ConfirmationDialog from "./components/ConfirmationDialog.vue"
import ErrorDialog from "./components/ErrorDialog.vue"

import SortableTableHeader from "./components/SortableTableHeader.vue";
import SortableHeader from "./components/SortableHeader.vue";
import LocalizableTextArea from "./components/LocalizableTextArea.vue";

import WindowPortal from "./components/WindowPortal.vue";

import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import "./services/Filters";
import { DataClient } from "@/services/DataClient";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const mask = require("v-mask");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const GmapVue = require("gmap-vue");


// import VueMarkdownEditor from '@kangc/v-md-editor';
const VueMarkdownEditor = require("@kangc/v-md-editor");
const VueMarkdownPreview = require("@kangc/v-md-editor/lib/preview");
import '@kangc/v-md-editor/lib/style/base-editor.css';
// import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
const vuepressTheme = require("@kangc/v-md-editor/lib/theme/vuepress.js");
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
const esES = require("@kangc/v-md-editor/lib/lang/es-ES");

import VueExcelViewer from '@uublue/vue-excel-viewer'
import '@uublue/vue-excel-viewer/lib/vue-excel-viewer.css'

//import Prism from 'prismjs';
const Prism = require("prismjs");

VueMarkdownEditor.use(vuepressTheme, {
    Prism,
});
VueMarkdownPreview.use(vuepressTheme, {
    Prism,
});
VueMarkdownEditor.lang.use("es-ES", esES.default);

Vue.config.productionTip = false;

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.use(GmapVue, {
    load: {
        key: "AIzaSyAP_k3Z7wZuKRObqWdbZ7ultMHfyc-A-Hg"
    },
    installComponents: true
});

Vue.use(VueMarkdownEditor);
Vue.use(VueMarkdownPreview);

Vue.use(VueExcelViewer)

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
Vue.use(VueViewer);

Vue.use(PerfectScrollbar)
Vue.directive("mask", mask.VueMaskDirective);
Vue.use(VueVirtualScroller)
Vue.component("PerfectScrollbar2", ps as any);
Vue.component("EditArea", EditArea);
Vue.component("SearchToolBar", SearchToolBar);
Vue.component("EditToolBar", EditToolBar);
Vue.component("InnerToolBar", InnerToolBar);
Vue.component("EditInnerToolBar", EditInnerToolBar);
Vue.component("LoaderAnimation", LoaderAnimation);
Vue.component("ConfirmationDialog", ConfirmationDialog);
Vue.component("ErrorDialog", ErrorDialog);

Vue.component("RichTextArea", () => import("./components/RichTextArea.vue"));

Vue.component("SortableTableHeader", SortableTableHeader);
Vue.component("SortableHeader", SortableHeader);
Vue.component("LocalizableTextArea", LocalizableTextArea);

Vue.component("ErrorCard", () => import("./components/ErrorCard.vue"));

Vue.component("LanguageIcon", () => import("./components/LanguageIcon.vue"));
Vue.component("ZoneDropdown", () => import("./components/Dropdowns/ZoneDropdown.vue"));
Vue.component("GenericDropdown", () => import("./components/Dropdowns/GenericDropdown.vue"));
Vue.component("DateDropdown", () => import("./components/Dropdowns/DateDropdown.vue"));
Vue.component("DateRangeDropdown", () => import("./components/Dropdowns/DateRangeDropdown.vue"));
Vue.component("LanguageButtonBar", () => import("./components/Dropdowns/LanguageButtonBar.vue"));
Vue.component("ViewSelectorDropdown", () => import("./components/Dropdowns/ViewSelectorDropdown.vue"));

Vue.component("WindowPortal", WindowPortal);


// VirtualTable
Vue.component("VirtualTable", () => import("./components/VirtualTable/VirtualTable.vue"));
Vue.component("VirtualTableHeader", () => import("./components/VirtualTable/VirtualTableHeader.vue"));
Vue.component("VirtualTableColumn", () => import("./components/VirtualTable/VirtualTableColumn.vue"));
Vue.component("VirtualPerfectScrollTable", () => import("./components/VirtualTable/VirtualPerfectScrollTable.vue"));
Vue.component("InfiniTable", () => import("./components/VirtualTable/InfiniTable.vue"));


// Editors
Vue.component("EditableArea", () => import("./components/Editors/EditableArea.vue"));
Vue.component("EditableSection", () => import("./components/Editors/EditableSection.vue"));
Vue.component("EditableSectionSeparator", () => import("./components/Editors/EditableSectionSeparator.vue"));
Vue.component("EditableCode", () => import("./components/Editors/EditableCode.vue"));
Vue.component("EditablePassword", () => import("./components/Editors/EditablePassword.vue"));
Vue.component("EditableEmail", () => import("./components/Editors/EditableEmail.vue"));
Vue.component("EditableAmount", () => import("./components/Editors/EditableAmount.vue"));
Vue.component("EditableDate", () => import("./components/Editors/EditableDate.vue"));
Vue.component("EditableTextArea", () => import("./components/Editors/EditableTextArea.vue"));
Vue.component("EditableZoneSelector", () => import("./components/Editors/EditableZoneSelector.vue"));
Vue.component("EditableActivitySelector", () => import("./components/Editors/EditableActivitySelector.vue"));
Vue.component("EditableProviderSelector", () => import("./components/Editors/EditableProviderSelector.vue"));
Vue.component("EditableRadioButtonGroup", () => import("./components/Editors/EditableRadioButtonGroup.vue"));
Vue.component("EditableToggle", () => import("./components/Editors/EditableToggle.vue"));
Vue.component("EditableCheckBoxGroup", () => import("./components/Editors/EditableCheckBoxGroup.vue"));
Vue.component("EditableLocalizableTextArea", () => import("./components/Editors/EditableLocalizableTextArea.vue"));
Vue.component("EditableLocalizableSingleTextField", () => import("./components/Editors/EditableLocalizableSingleTextField.vue"));
Vue.component("EditableLocalizableSingleTextArea", () => import("./components/Editors/EditableLocalizableSingleTextArea.vue"));
Vue.component("EditableLocalizableSingleMarkdownTextArea", () => import("./components/Editors/EditableLocalizableSingleMarkdownTextArea.vue"));
Vue.component("EditableLocalizableSingleRichTextArea", () => import("./components/Editors/EditableLocalizableSingleRichTextArea.vue"));
Vue.component("EditableMeetingPointSelector", () => import("./components/Editors/EditableMeetingPointSelector.vue"));
Vue.component("EditableGeolocation", () => import("./components/Editors/EditableGeolocation.vue"));
Vue.component("EditableFiscalInformation", () => import("./components/Editors/EditableFiscalInformation.vue"));
Vue.component("EditableContactInformation", () => import("./components/Editors/EditableContactInformation.vue"));
Vue.component("EditableOperationalInformation", () => import("./components/Editors/EditableOperationalInformation.vue"));
Vue.component("EditableContentElements", () => import("./components/Editors/EditableContentElements.vue"));
Vue.component("EditableImages", () => import("./components/Editors/EditableImages.vue"));
Vue.component("EditableSingleImage", () => import("./components/Editors/EditableSingleImage.vue"));
Vue.component("EditableDocuments", () => import("./components/Editors/EditableDocuments.vue"));
Vue.component("EditableDocument", () => import("./components/Editors/EditableDocument.vue"));
Vue.component("EditableActivityCategoryList", () => import("./components/Editors/EditableActivityCategoryList.vue"));
Vue.component("EditableActivityList", () => import("./components/Editors/EditableActivityList.vue"));

//Vue.component("EditableMessageTemplateSelector", () => import("./components/Editors/EditableMessageTemplateSelector.vue"));
//Vue.component("EditableSeriesSelector", () => import("./components/Editors/EditableSeriesSelector.vue"));
//Vue.component("EditableHotelSelector", () => import("./components/Editors/EditableHotelSelector.vue"));

Vue.component("EditablePortableAggregateReferenceSelector", () => import("./components/Editors/EditablePortableAggregateReferenceSelector.vue"));
Vue.component("EditablePortableAggregateReferenceList", () => import("./components/Editors/EditablePortableAggregateReferenceList.vue"));


// Filters
Vue.component("GenericFilterMenu", () => import("./components/Filters/GenericFilterMenu.vue"));


// Register the router hooks with their names
Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate'
]);

async function start() {
    // refresh credentials if needed
    const LocalStorageKeyName = "ib:crs";
    const storedDetails = localStorage.getItem(LocalStorageKeyName)
        ? JSON.parse(localStorage.getItem(LocalStorageKeyName) as string)
        : null;

    if (storedDetails) {
        localStorage.removeItem(LocalStorageKeyName);

        try {
            const r = await DataClient.Users.logIn({ token: storedDetails.token });
            localStorage.setItem(LocalStorageKeyName, JSON.stringify(r));
        } catch (e) {
            console.error(e);
        }
    }

    new Vue({
        router,
        vuetify,
        pinia,
        render: (h) => h(App),
    }).$mount("#app");

}

start();
