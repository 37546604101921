import { Vue } from "vue-property-decorator";
import { EventBus } from "@/components/EventBus";
import { ApiError } from "@/services/BaseService";
import { DataClient } from "@/services/DataClient";
import { Utilities } from "@/services/Utilities";
import { loginStore } from "@/stores/LoginStore";

export class BaseView extends Vue {

    saving = false;
    needToReplaceRoute = false;

    get instanceName(): string {
        // check if the url contains "palmaaquarium" in the host name
        const host = window.location.hostname;

        const isPalmaAquarium = host.includes("palmaaquarium");
        if (isPalmaAquarium) {
            return "palmaaquarium";
        }

        const isPreEnvironment = host.includes("pre-");
        if (isPreEnvironment) {
            return "pre";
        }

        return "";
        //return "palmaaquarium";
    }

    get applicationDrawerOpen(): boolean {
        return (this.$parent.$parent.$parent.$parent as any).drawer || false;
    }

    getReference<T>(name: string): T {
        return (this as any).$refs[name] as T;
    }

    hasPermission(permission = ""): boolean {
        const store = loginStore();
        if (store.details?.isSuperUser) {
            return true;
        }

        const permissions = store.details?.permissions || [];
        return permissions.includes(permission) || false;
    }

    async openConfirmationDialog(title: string, message: string): Promise<boolean> {
        const yes = await DataClient.Dialogs.confirmation({
            title: title,
            message: message,
            icon: "mdi-help-circle-outline",
        });

        return yes === true;
    }

    getErrorMessageFromException(error: any): string {
        let message = "";
        if (error instanceof ApiError) {
            message = error.errorMessage;
        } else if (error instanceof Error) {
            message = error.message;
        } else {
            message = error;
        }

        message = message || "Error desconocido";

        return message;
    }

    async openErrorDialog(title: string, error: any): Promise<void> {
        const message = this.getErrorMessageFromException(error);

        await DataClient.Dialogs.confirmation({
            title: "Error durante la operación",
            message: "Ha ocurrido un error durante la operación. Por favor, inténtelo de nuevo más tarde. Si el problema persiste, contacte con el administrador del sistema.",
            messages: [
                "El mensaje de error devuelto por el sistema es el siguiente:",
                "<strong>" + message + "</strong>"
            ],
            nocancel: true,
            icon: "mdi-alert-circle-outline",
            iconColor: "error",
            color: "error",
            buttonColor: "error",
        });
    }

    async secureLongRunningExecution<T = void>(action: (status: (message: string) => void) => Promise<T>, errorHandler?: () => Promise<void>) {
        DataClient.Dialogs.openProgress({ title: "Ejecutando operación" });

        try {
            const r = await action((message: string) => DataClient.Dialogs.updateProgress({ message }));

            DataClient.Dialogs.closeProgress();

            return r;
        } catch (error) {
            DataClient.Dialogs.closeProgress();

            await this.openErrorDialog("Ha ocurrido un error", error);

            if (errorHandler) {
                await errorHandler();
            }
        }
    }

    async secureExecution<T = void>(action: () => Promise<T>, errorHandler?: () => Promise<void>) {
        try {
            return await action();
        } catch (error) {
            await this.openErrorDialog("Ha ocurrido un error", error);

            if (errorHandler) {
                await errorHandler();
            }
        }
    }

    async secureSavingExecution<T = void>(action: () => Promise<T>, errorHandler?: () => Promise<void>) {
        try {
            this.saving = true;
            return await action();
        } catch (error) {
            await this.openErrorDialog("Ha ocurrido un error", error);

            if (errorHandler) {
                await errorHandler();
            }
        } finally {
            this.scrollToTop();
            this.saving = false;
        }
    }

    async sleep(milliseconds: number) {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    beforeRouteLeave(to: any, from: any, next: any) {
        if (this.needToReplaceRoute) {
            this.needToReplaceRoute = false;
            next({ ...to, replace: true });
        } else {
            next();
        }
    }

    scrollToTop() {
        EventBus.$emit("scrollToTop");
    }

    updateScroll() {
        EventBus.$emit("updateScroll");
    }

    updateLocalScroll(refName: string) {
        const ref = (this.$refs as any)[refName] as any;

        if (ref) {
            setTimeout(() => {
                ref.update();
            }, 100);
        }
    }

    focusFirstEditableComponent() {
        setTimeout(() => {
            EventBus.$emit("edition:focusFirstEditableComponent");
        }, 100);
    }

    formatPrice(value: number): string {
        if (value !== undefined && value !== null) {
            return `${value.toFixed(2)} €`;
        } else {
            return "";
        }
    }

    formatDate(value: string): string {
        return Utilities.formatDateToDDMMYYYY(value);
    }

    dump(value: any) {
        console.log("Dump", value);
    }

    checkValidity(...values: any[]): boolean {
        return values.every(value => value !== undefined && value !== null && value !== "");
    }

    selectAllText(event: any) {
        const target = event?.target;
        if (target && target.select) {
            target.select();
        }
    }

    isNumber(evt: KeyboardEvent, allowDot = true): void {
        const keysAllowed: string[] = allowDot ? ["-", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."] : ["-", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        const keyPressed: string = evt.key;

        if (!keysAllowed.includes(keyPressed)) {
            evt.preventDefault();
        }
    }

}
